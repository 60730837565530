import React, { useRef, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

import { SlideContainer, ImagesGrid, GridImage } from './styles'

const ProductImages = ({ product }) => {
  const slideRef = useRef()
  const [slidePosition, setSlidePosition] = useState(0)

  const properties = {
    duration: 5000,
    transitionDuration: 350,
    infinite: true,
    indicators: false,
    autoplay: false,
    arrows: true,
    onChange: (previous, next) => {
      setSlidePosition(next)
    },
  }

  const goto = i => slideRef.current.goTo(i)

  if (product.images) {
    return (
      <div>
        <SlideContainer>
          <Slide {...properties} ref={slideRef}>
            {product.images.map(i => (
              <div className="each-fade" key={i.id}>
                <GatsbyImage
                  image={i.localFile.childImageSharp.gatsbyImageData}
                  alt={product.title}
                  key={i.id}
                />
              </div>
            ))}
          </Slide>
        </SlideContainer>

        <ImagesGrid>
          {product.images.map((image, i) => (
            <GridImage
              image={image.localFile.childImageSharp.gatsbyImageData}
              alt={product.title}
              key={image.id}
              onClick={() => goto(i)}
              opacity={i === slidePosition ? '1' : '0.5'}
            />
          ))}
        </ImagesGrid>
      </div>
    )
  }
}

export default ProductImages

import React from 'react'
import { Link, Trans } from 'gatsby-plugin-react-i18next'
import { BsChevronRight } from '@react-icons/all-files/bs/BsChevronRight'

import { List, ListItem } from './styles'

const CollectionList = ({ categories }) => (
  <div>
    <h3 style={{ marginTop: '0' }}>
      <Trans>Product Categories</Trans>
    </h3>
    <List>
      {categories.map(({ id, handle, title, productsCount }) => (
        <ListItem key={id}>
          <Link
            to={`/shop/category/${handle}/`}
            style={{ textDecoration: 'none' }}
          >
            <BsChevronRight size={12} />
            {title} <span style={{ color: 'grey' }}>({productsCount})</span>
          </Link>
        </ListItem>
      ))}
    </List>
  </div>
)

export default CollectionList

import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'

export const SlideContainer = styled.div`
  width: 100%;
`

export const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  justify-content: space-around;
`

export const GridImage = styled(GatsbyImage)`
  cursor: pointer;
  opacity: ${({ opacity }) => (opacity ? opacity : '1')};
`

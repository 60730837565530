import React from 'react'
import { graphql } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'

import Seo from '../../components/seo'
import Navigation from '../../components/Navigation'
import ProductForm from '../../components/ProductForm'
import Footer from '../../components/Footer'
import ProductImages from '../../components/ProductImages'
import {
  Spacer,
  breakpoints,
  MainContent,
  TwoColumnGrid,
  ContainerExtraLarge,
} from '../../utils/styles'
import { ProductTitle, ProductDescription, Grid } from './styles'
import CollectionList from '../../components/CollectionList'
import { useWindowDimensions } from '../../utils/hooks'
import { transformTranslation } from '../../utils/helpers'

const ProductPage = ({ data, pageContext }) => {
  const { width } = useWindowDimensions()
  const { product, translatedProduct, categories, translatedCategories } = data

  const tProduct = {
    ...product,
    ...translatedProduct,
  }
  const tCategories = transformTranslation(categories, translatedCategories)

  const specs = tProduct?.metafields?.edges.filter(
    ({ node }) => node.key === 'specs'
  )[0]?.node

  return (
    <>
      <Seo
        title={tProduct.title}
        description={tProduct.description}
        thumbnail={tProduct.featuredImage.originalSrc}
      />
      <Navigation />
      <ContainerExtraLarge style={{ marginTop: '120px' }}>
        <MainContent>
          <Grid>
            {width > breakpoints.l && (
              <CollectionList categories={tCategories} />
            )}
            <TwoColumnGrid>
              <ProductImages product={tProduct} />
              <div>
                <ProductTitle>{tProduct.title}</ProductTitle>
                <ProductForm product={tProduct} />
                <ProductDescription
                  dangerouslySetInnerHTML={{
                    __html: tProduct.descriptionHtml,
                  }}
                />
                {specs && (
                  <>
                    <h2>
                      <Trans>Specs</Trans>
                    </h2>
                    <div dangerouslySetInnerHTML={{ __html: specs.value }} />
                  </>
                )}
              </div>
            </TwoColumnGrid>
          </Grid>
          <Spacer height="5rem" />
        </MainContent>
      </ContainerExtraLarge>
      <Footer />
    </>
  )
}

export const query = graphql`
  query ($locale: String!, $language: String!, $storefrontId: String!) {
    product: shopifyProduct(storefrontId: { eq: $storefrontId }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      storefrontId
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        storefrontId
        compareAtPrice
        selectedOptions {
          name
          value
        }
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      featuredImage {
        originalSrc
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
    }
    translatedProduct: shopifyTranslatedProduct(
      storefrontId: { eq: $storefrontId }
      locale: { eq: $locale }
    ) {
      title
      locale
      productType
      description
      descriptionHtml
      metafields {
        edges {
          node {
            key
            value
            id
            description
          }
        }
      }
    }
    categories: allShopifyCollection(filter: { title: { ne: "Frontpage" } }) {
      edges {
        node {
          id
          handle
          title
          productsCount
          storefrontId
        }
      }
    }
    translatedCategories: allShopifyTranslatedCollection(
      filter: { locale: { eq: $language } }
    ) {
      edges {
        node {
          title
          description
          descriptionHtml
          locale
          storefrontId
        }
      }
    }
    i18nLocales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ProductPage

import React, { useState, useContext, useEffect, useCallback } from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'

import StoreContext from '../../context/StoreContext'
import LayoutContext from '../../context/LayoutContext'
import { calcuateGrossPrice } from '../../utils/helpers'
import {
  Button,
  colors,
  ButtonWrapper,
  QuantityButton,
  QuantityInput,
  Select,
} from '../../utils/styles'
import Spinner from '../ui/Spinner'
import {
  Wrapper,
  ProductOptions,
  OptionName,
  OptionValues,
  ProductPrice,
  ComparePrice,
  PriceInfo,
} from './styles'

const ProductForm = ({ product, dark = false, color }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2: { minVariantPrice },
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuanity] = useState(1)
  const [loading, setLoading] = useState(false)
  const { client, adding, addVariantToCart, countryCode } =
    useContext(StoreContext)
  const { toggleCart } = useContext(LayoutContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    async productId => {
      setLoading(true)
      const fetchedProduct = await client.product.fetch(productId)
      const result = fetchedProduct.variants.filter(
        variant => variant.id === productVariant.storefrontId
      )
      setAvailable(result[0]?.available ?? fetchedProduct.variants[0].available)
      setLoading(false)
    },
    [client.product, productVariant.shopifyId]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [checkAvailability, product.shopifyId])

  const handleChange = (optionIndex, { target: { value } }) => {
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = async () => {
    await addVariantToCart(productVariant.shopifyId, quantity)
    toggleCart()
  }

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(calcuateGrossPrice(variant.price, countryCode))

  const buyButtonContent = () => {
    if (loading) return <Spinner />
    if (available) return <Trans>Add to Cart</Trans>
    return <Trans>Sold out</Trans>
  }

  const handleQuantity = ({ target }) => {
    switch (target.name) {
      case 'plus':
        setQuanity(quantity + 1)
        break
      case 'minus':
        if (quantity > 1) setQuanity(quantity - 1)
        break
      default:
        break
    }
  }

  return (
    <Wrapper>
      <div style={{ marginBottom: '2rem' }}>
        {variant?.compareAtPrice && (
          <ComparePrice>
            {calcuateGrossPrice(variant?.compareAtPrice, countryCode)}
          </ComparePrice>
        )}
        <ProductPrice color={colors.androidGreen}>{price}</ProductPrice>
        <br />
        <br />
        <PriceInfo>
          <Trans>Price inlcuding VAT based on your IP location.</Trans>
        </PriceInfo>
      </div>

      {options[0].name !== 'Title' &&
        options.map(({ id, name, values }, optionIndex) => (
          <ProductOptions key={id}>
            <OptionName color={color}>{name}</OptionName>
            <Select onChange={value => handleChange(optionIndex, value)}>
              {values.map(value => (
                <option value={value} key={`${id}-${value}`}>
                  {value}
                </option>
              ))}
            </Select>
            <OptionValues></OptionValues>
          </ProductOptions>
        ))}

      <ButtonWrapper style={{ marginBottom: '1rem' }}>
        <QuantityButton onClick={handleQuantity} name="minus">
          -
        </QuantityButton>
        <QuantityInput type="number" value={quantity} disabled min={1} />
        <QuantityButton onClick={handleQuantity} name="plus">
          +
        </QuantityButton>
      </ButtonWrapper>
      <Button
        color={dark ? 'black' : 'white'}
        backgroundColor={dark ? 'white' : 'black'}
        type="submit"
        disabled={!available || adding || loading}
        onClick={handleAddToCart}
        role="button"
      >
        {buyButtonContent()}
      </Button>
    </Wrapper>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm

import styled from '@emotion/styled'

import { breakpoints } from '../../utils/styles'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 80%;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};

  @media (max-width: ${breakpoints.l}px) {
    display: block;

    &:first-of-type {
      margin-bottom: 5rem;
    }
  }
`

export const ProductTitle = styled.h1`
  font-size: 2.25rem;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  margin: 0 0 0.5rem;
  line-height: 1.4;
`

export const ProductDescription = styled.div`
  margin-top: 40px;
  font-weight: 300;
`

import styled from '@emotion/styled'

import { colors } from '../../utils/styles'

export const List = styled.ul`
  list-style-type: none;
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: solid lightgrey 1px;

    a {
      color
    }
  }
`

export const ListItem = styled.li`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: solid lightgrey 1px;

  a {
    color: black;
    tex-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: ${colors.androidGreen};
    }
  }
`
